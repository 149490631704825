import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  padding: auto;
  height: 100%;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  margin: 20% auto;
`

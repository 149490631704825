import gql from "graphql-tag";
import { query } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const activityQuery = gql`
  query activity($id: ID!) {
    activity(id: $id) {
      data {
        ...activityDetails
      }
    }
  }
  ${activitiesDetailsFragment}
`;

export default query(activityQuery);
/*
export default (...params) =>
  query(activityQuery)(...params).then(response => {
    const data = response.data.data;

    response.data.data = {
      ...data,
      groups: data.groups.nodes
    };

    return response;
  });
*/

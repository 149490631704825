import gql from "graphql-tag";
import { query } from "services/api";
import { teamMemberDetailsFragment } from "graphql/team-member/fragments/team-member-details";

export const teamMembersQuery = gql`
  query teamMembers($input: TeamMembersInput) {
    teamMembers(input: $input) {
      totalCount
      count
      nodes {
        ...teamMemberDetails
      }
    }
  }
  ${teamMemberDetailsFragment}
`;

export default query(teamMembersQuery);

import Response from "services/api/response";

class ResponseError extends Response {
  constructor(data) {
    const response = data.response ? data.response : data;
    super(response);

    const { name, stack, message } = data;

    this.error = {
      name,
      stack,
      message,
    };

    this.request = data.request;
    this.data = null;
  }
}

export default ResponseError;

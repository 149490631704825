import gql from "graphql-tag";
import { query } from "services/api";
import { activityGroupDetailsFragment } from "graphql/activity-group/fragments/activity-group-details";

export const activityGroupQuery = gql`
  query activityGroup($id: ID!) {
    activityGroup(id: $id) {
      data {
        ...activityGroupDetails
      }
    }
  }
  ${activityGroupDetailsFragment}
`;

export default query(activityGroupQuery);

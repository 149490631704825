import gql from "graphql-tag";
import { mutation } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const createActivityMutation = gql`
  mutation createActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      data {
        ...activityDetails
      }
      error
    }
  }
  ${activitiesDetailsFragment}
`;

export default mutation(createActivityMutation);

import { noter } from "services/noter";
import { ENV_CONFIG} from "config";

export const OAUTH_TOKEN_NAME = 'fnd_auth_token'

/**
 * The auth provider for the ReactAdmin.
 * @type {{
 *    logout: (function(): Promise<void>),
 *    getPermissions: (function(): Promise<void>),
 *    checkAuth: authProvider.checkAuth,
 *    checkError: (function(): Promise<void>),
 *    login: (function(Object=): Promise<void>)}}
 */
export const authProvider = {
  /**
   * Login attempt with username and password.
   * Not used with oAuth.
   * @param {Object} params
   * @returns {Promise<void>}
   */
  login: (params = {}) => {
    noter.info(`[AUTH] login with: ${JSON.stringify(params)}`)
    return Promise.resolve()
  },
  /**
   * Logout button call
   * @returns {Promise<void>}
   */
  logout: () => {
    noter.info('[AUTH] logout')
    localStorage.removeItem(OAUTH_TOKEN_NAME)
    return Promise.resolve()
  },
  /**
   * API returns an error
   * @returns {Promise<void>}
   */
  checkError: () => {
    noter.info('[AUTH] check error')
    return Promise.resolve()
  },
  /**
   * On each navigation as to check for authentication
   * @returns {Promise<never>|Promise<void>|Promise<Object>}
   */
  checkAuth: () => {
    noter.info('[AUTH] check auth')
    if (!ENV_CONFIG.ENABLE_AUTH) {
      return Promise.resolve()
    }

    const token = localStorage.getItem(OAUTH_TOKEN_NAME);
    if (!token) {
      return Promise.reject({
        redirectTo: '/login'
      });
    }

    return Promise.resolve();
  },
  /**
   * On each navigation as to check for  permissions / roles
   * @returns {Promise<void>}
   */
  getPermissions: () => {
    noter.info('[AUTH] get permissions')
    return Promise.resolve()
  },
}

export default authProvider

import React from "react";
import { Admin, Resource } from "react-admin";
import { dataProvider } from "providers/data-provider";

import ListActivity from "resources/activity/list";
import CreateActivity from "resources/activity/create";
import EditActivity from "resources/activity/edit";

import ListActivityGroup from "resources/activity-group/list";
import CreateActivityGroup from "resources/activity-group/create";

import ListTeamMember from "resources/team-member/list";
import CreateTeamMember from "resources/team-member/create";
import EditTeamMember from "resources/team-member/edit";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GetToken } from "components/auth/getToken";
import { LoginPage } from "screens/login";
import { authProvider } from "providers/auth-provider";
import { ENV_CONFIG } from "config";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/token">
        <GetToken />
      </Route>
      <Route path="/">
        <Admin
          loginPage={ENV_CONFIG.ENABLE_AUTH ? LoginPage : undefined}
          authProvider={ENV_CONFIG.ENABLE_AUTH ? authProvider : undefined}
          dataProvider={dataProvider}
        >
          <Resource
            name="Activity"
            list={ListActivity}
            edit={EditActivity}
            create={CreateActivity}
          />
          <Resource
            name="ActivityGroup"
            list={ListActivityGroup}
            create={CreateActivityGroup}
            options={{ label: "Activity Group" }}
          />
          <Resource
            name="TeamMember"
            list={ListTeamMember}
            edit={EditTeamMember}
            create={CreateTeamMember}
            options={{ label: "Team Members" }}
          />
          <Resource name="WorkLog" options={{ label: "Work Logs" }} />
          <Resource name="Task" options={{ label: "Tasks" }} />
          <Resource name="JiraTempoAccount" />
          <Resource name="ToggleProject" />
        </Admin>
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

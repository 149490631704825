import gql from "graphql-tag";
import { query } from "services/api";
import { workLogDetailsFragment } from "graphql/work-log/fragments/work-log-details";

export const workLogsQuery = gql`
  query workLogs($input: WorkLogsInput!) {
    workLogs(input: $input) {
      totalCount
      count
      nodes {
        ...workLogDetails
      }
    }
  }
  ${workLogDetailsFragment}
`;

export default query(workLogsQuery);

import React from "react";
import { List } from "react-admin";

function ListComponent({ resourceName, children, ...props }) {
  const fakeProps = {
    ...props,
    basePath: "/" + resourceName,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: resourceName
  };

  return <List {...fakeProps}>{children}</List>;
}

export default ListComponent;

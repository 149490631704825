import gql from "graphql-tag";

export const workLogDetailsFragment = gql`
  fragment workLogDetails on WorkLog {
    id
    description
    start
    end
    duration {
      value
      label
    }
    tags
    author {
      id
      fullName
    }
    updatedAt
  }
`;

import gql from "graphql-tag";
import { query } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const activitiesQuery = gql`
  query activities($input: ActivitiesInput) {
    activities(input: $input) {
      totalCount
      count
      nodes {
        ...activityDetails
      }
    }
  }
  ${activitiesDetailsFragment}
`;

export default query(activitiesQuery);

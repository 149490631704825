import { ENV_CONFIG } from "config";

/**
 * Interface some coloured terminal logging.
 *
 * Provides four types of logs:
 * <ul>
 *     <li><b>info (blue)</b>       - operational messages that require no action</li>
 *     <li><b>log (yellow)</b>      - useful information (in terms of debugging for instance)</li>
 *     <li><b>warn (magenta)</b>    - do not stops the execution but have the potential to</li>
 *     <li><b>error (red)</b>       - errors that are stopping some operational flow</li>
 * </ul>
 */
export class Noter {
  constructor(moduleName) {
    this._moduleName = moduleName || 'FND'
  }

  /**
   * Do the actual logging.
   */
  _thisLogger(noteType, message, ...args) {
    if (!ENV_CONFIG.LOG_DEBUG) {
      return
    }

    console[noteType](`[${ this._moduleName }] ${ message.toString() }`, ...args)
  }

  /**
   * Produce an error message.
   */
  error(logMessage, ...args) {
    this._thisLogger('error', logMessage, ...args)
  }

  /**
   * Produce a info message.
   */
  info(logMessage, ...args) {
    this._thisLogger('info', logMessage, ...args)
  }

  /**
   * Produce a warning message.
   */
  warn(logMessage, ...args) {
    this._thisLogger('warn', logMessage, ...args)
  }

  /**
   * Produce a log message.
   */
  log(logMessage, ...args) {
    this._thisLogger('log', logMessage, ...args)
  }
}

export const noter = Object.freeze(new Noter())

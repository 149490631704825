import activity from "graphql/activity";
import activityGroup from "graphql/activity-group";
import teamMember from "graphql/team-member";
import workLog from "graphql/work-log";
import task from "graphql/task";
import taskProject from "graphql/task-project";
import workLogProject from "graphql/work-log-project";
import jiraTempoAccount from "graphql/jira-tempo-account";
import toggleProject from "graphql/toggle-project";

export const types = {
  activity,
  teamMember,
  activityGroup,
  workLog,
  task,
  taskProject,
  workLogProject,
  jiraTempoAccount,
  toggleProject
};

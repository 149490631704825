import gql from "graphql-tag";
import { mutation } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const deleteActivitiesMutation = gql`
  mutation deleteActivities($ids: [ID!]!) {
    deleteActivities(ids: $ids) {
      data {
        ...activityDetails
      }
      error
    }
  }
  ${activitiesDetailsFragment}
`;

export default mutation(deleteActivitiesMutation);

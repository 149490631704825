import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  BooleanInput,
  TextInput,
  DateTimeInput,
  NumberInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  ArrayInput,
  CheckboxGroupInput,
  SimpleFormIterator
} from "react-admin";

import { required, minValue, number } from "react-admin";

import Grid from "@material-ui/core/Grid";

import { FormSelectInput } from "components/forms/select";

import workLogProject from "graphql/work-log-project/queries/work-log-project";
import taskProject from "graphql/task-project/queries/task-project";
import toggleProjects from "graphql/toggle-project/queries/toggle-projects";
import jiraTempoAccounts from "graphql/jira-tempo-account/queries/jira-tempo-accounts";

const testData = {
  name: "Activity test 1",
  description: "Desc",
  state: "IN_PROGRESS",
  type: "CUSTOMER_PROJECT",
  dueDate: "2020-10-24T05:32:46.053Z",
  billable: true,
  groups: ["5e1efb29-e020-4615-b2a0-f29535826374"],
  salesBudgetEstimate: 500,
  productBudgetEstimate: 500,
  customer: "KayX"
};

/**
 *
 * If activity is linked to account ot project via id and not name
 */

export const CreateActivity = props => {
  const previousTaskProjectId = React.useRef(null);
  const previousWorkLogProjectId = React.useRef(null);
  const previousName = React.useRef("");
  const errors = React.useRef({});

  async function validate(values) {
    const { workLogProjectId, taskProjectId, name } = values;

    if (
      workLogProjectId &&
      workLogProjectId !== previousWorkLogProjectId.current
    ) {
      const projectResponse = await workLogProject({
        input: { reference: workLogProjectId }
      });

      const reference = projectResponse.getField("data.reference");

      if (reference) {
        errors.current.workLogProjectId = [
          `Toggle project is already linked to another activity with id ${projectResponse.getField(
            "data.activityId"
          )}`
        ];
      } else {
        errors.current.workLogProjectId = undefined;
      }

      if (name) {
        await validateNameAgainstToggleProject(values);
      }
    }

    if (taskProjectId && taskProjectId !== previousTaskProjectId.current) {
      const projectResponse = await taskProject({
        input: { reference: taskProjectId }
      });

      const reference = projectResponse.getField("data.reference");

      if (reference) {
        errors.current.taskProjectId = [
          `Jira tempo account is already linked to another activity with id ${projectResponse.getField(
            "data.activityId"
          )}`
        ];
      } else {
        errors.current.taskProjectId = undefined;
      }

      if (name) {
        await validateNameAgainstJiraTempoAccount(values);
      }
    }

    if (name && previousName !== name) {
      if (workLogProjectId) {
        await validateNameAgainstToggleProject(values);
      }

      if (taskProjectId) {
        await validateNameAgainstJiraTempoAccount(values);
      }
    }

    previousTaskProjectId.current = taskProjectId;
    previousWorkLogProjectId.current = workLogProjectId;

    return errors.current;
  }

  async function validateNameAgainstToggleProject(values) {
    const { workLogProjectId } = values;
    const toggleProjectResponse = await toggleProjects();

    const toggleProject = (toggleProjectResponse.getData() || []).find(
      ({ id }) => id === workLogProjectId
    );

    const nameErrorMessage = `Activity name must match toggle project name`;

    if (toggleProject) {
      if (toggleProject.name !== values.name) {
        if (
          (errors.current.name || []).filter(
            message => message === nameErrorMessage
          ).length === 0
        ) {
          errors.current.name = [
            ...(errors.current.name || []),
            nameErrorMessage
          ];
        }
      } else {
        errors.current.name = (errors.current.name || []).filter(
          message => message !== nameErrorMessage
        );

        if (errors.current.name.length === 0) {
          errors.current.name = undefined;
        }
      }
    }
  }

  async function validateNameAgainstJiraTempoAccount(values) {
    const jiraTempoAccountResponse = await jiraTempoAccounts({
      input: { filter: { ids: [values.taskProjectId] } }
    });

    const [jiraTempoAccount] = jiraTempoAccountResponse.getData();

    const nameErrorMessage = `Activity name must match jira tempo account name`;

    if (jiraTempoAccount) {
      if (jiraTempoAccount.name !== values.name) {
        if (
          (errors.current.name || []).filter(
            message => message === nameErrorMessage
          ).length === 0
        ) {
          errors.current.name = [
            ...(errors.current.name || []),
            nameErrorMessage
          ];
        }
      } else {
        errors.current.name = (errors.current.name || []).filter(
          message => message !== nameErrorMessage
        );

        if (errors.current.name.length === 0) {
          errors.current.name = undefined;
        }
      }
    }
  }

  return (
    <Create {...props}>
      {/*<TabbedForm initialValues={testData}>*/}
      <TabbedForm validate={validate}>
        <FormTab label="activity">
          <Grid container style={{ width: "25%" }} direction="column">
            <TextInput source="name" validate={required()} />
            <TextInput source="description" multiline validate={required()} />
            <ReferenceInput
              label="Link to jira tempo account (optional)"
              source="taskProjectId"
              reference="JiraTempoAccount"
            >
              <AutocompleteInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
            <ReferenceInput
              label="Link to toggle project (optional)"
              source="workLogProjectId"
              reference="ToggleProject"
            >
              <AutocompleteInput optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
            <FormSelectInput
              enumName={"ActivityStateEnum"}
              type={"SelectInput"}
              name={"State"}
              validate={required()}
            />
            <FormSelectInput
              enumName={"ActivityTypeEnum"}
              type={"RadioButtonGroupInput"}
              name={"Type"}
              validate={required()}
            />
            <BooleanInput source="billable" defaultValue={false} />
            <DateTimeInput
              label="Due date"
              source="dueDate"
              validate={required()}
            />
            <ReferenceArrayInput
              label="Activity groups"
              source="groups"
              reference="ActivityGroup"
              validate={required()}
            >
              <AutocompleteArrayInput optionText="name" optionValue="id" />
            </ReferenceArrayInput>
            <NumberInput
              source="salesBudgetEstimate"
              validate={[required(), number(), minValue(0)]}
            />
            <NumberInput
              source="productBudgetEstimate"
              validate={[required(), number(), minValue(0)]}
            />
            <TextInput source="customer" validate={required()} />
          </Grid>
        </FormTab>
        <FormTab label="Team">
          <ArrayInput label="Activity team" source="team" validate={required()}>
            <SimpleFormIterator>
              <ReferenceInput
                label="Team member"
                source="teamMemberId"
                reference="TeamMember"
                validate={required()}
              >
                <AutocompleteInput optionText="fullName" optionValue="id" />
              </ReferenceInput>
              <AutocompleteArrayInput
                label="Roles"
                source="roles"
                choices={[
                  { id: "ENGINEER", name: "Engineer" },
                  { id: "DESIGNER", name: "Designer" },
                  { id: "PRODUCT_MANAGER", name: "Product Manager" },
                  { id: "MARKETING_ANALYST", name: "Marketing Analyst" },
                  { id: "QUALITY_ASSURANCE", name: "Quality Assurance" }
                ]}
                validate={required()}
              />

              <CheckboxGroupInput
                label="Lead roles"
                source="leadRoles"
                choices={[
                  { id: "ACTIVITY_LEAD", name: "Activity lead" },
                  { id: "TECH_LEAD", name: "Tech lead" },
                  { id: "DESIGN_LEAD", name: "Design lead" }
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CreateActivity;

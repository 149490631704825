import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ListPagination } from "components/forms/pagination";

export const TeamMemberList = props => (
  <List pagination={<ListPagination />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="fullName" />
      <TextField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="type" />
      <TextField source="state" />
      <TextField source="role" />
      <TextField source="location" />
      <TextField source="jobTitle" />
      <TextField source="remoteLocation" />
      <TextField source="grade" />
      <TextField source="clan" />
      <TextField source="school" />
      <TextField source="hourlyCost" />
    </Datagrid>
  </List>
);

export default TeamMemberList;

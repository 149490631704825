import client from "services/apollo";
import Response from "services/api/response";
import ResponseError from "services/api/response-error";
import { omit, isPlainObject, isArray } from "lodash";
import { compose, entries, last, size } from "lodash/fp";
import { notEmpty, asyncAction } from "utils/js";

const selector = response => {
  const { data } = response;

  if (size(data) > 1) {
    return data;
  }

  return compose(
    ([, data]) => data,
    last,
    entries
  )(data);
};

export const executeRequest = async callable => {
  const [error, response] = await asyncAction(callable());

  return error ? new ResponseError(error) : new Response(selector(response));
};

export const mutation = (
  mutation,
  options = { fetchPolicy: "no-cache" }
) => async (variables, ...rest) => {
  const [mutationOptions = options] = rest;

  const callable = () =>
    client.mutate({
      mutation,
      variables: removeTypeName(variables),
      ...mutationOptions
    });

  return executeRequest(callable);
};

export const query = (query, options = { errorPolicy: "all" }) => async (
  variables,
  ...rest
) => {
  const [queryOptions = options] = rest;
  const callable = () =>
    client.query({ query, variables, ...queryOptions });

  return executeRequest(callable);
};

function removeTypeName(variables = {}) {
  return Object.entries(variables).reduce((variables, [key, value]) => {
    let keyValue = value;

    if (isPlainObject(value)) {
      keyValue = removeTypeName(omit(value, ["__typename"]));
    }

    if (isArray(value) && notEmpty(value)) {
      keyValue = value.map(item => {
        if (isPlainObject(item)) {
          return removeTypeName(omit(item, ["__typename"]));
        }

        return item;
      });
    }

    return { ...variables, [key]: keyValue };
  }, {});
}

import React from "react";
import queryString from "query-string";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { get } from "lodash";

import { Loader } from "components/utility/Loader";
import { noter } from "services/noter";
import { ENV_CONFIG } from "config";
import { OAUTH_TOKEN_NAME } from "providers/auth-provider";

export const GetToken = () => {
  const location = useLocation();
  const history = useHistory();
  const { code } = queryString.parse(location.search);
  noter.info(`[OAUTH][GET-TOKEN] got code ${code}`)
  React.useEffect(() => {
    axios
      .post(`${ENV_CONFIG.FND_SERVICE_BASE_URL}/v1/users/google/issue-token`, { code })
      .then(response => {
        const success = get(response, 'data.success', false)
        const token = get(response, 'data.data.token', null)
        if (success && token) {
          noter.info(`[OAUTH][GET-TOKEN] got token ${token}`)
          localStorage.setItem(OAUTH_TOKEN_NAME, token)
          history.push('')
        } else {
          noter.error('[OAUTH][GET-TOKEN] got no token')
          history.push('/login')
        }
      }).catch((getTokenErr) => {
        noter.error(`[OAUTH][GET-TOKEN] error: ${getTokenErr.message}`)
        console.error(getTokenErr)
      });
  });

  return <Loader />;
};

import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import { ListPagination } from "components/forms/pagination";

export const ActivityList = props => (
  <List pagination={<ListPagination />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="type" />
      <TextField source="state" />
      <DateField source="dueDate" showTime />
      <BooleanField source="billable" />
      <ArrayField label="Groups" source="groups.nodes">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default ActivityList;

import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput
} from "react-admin";
import { required, email, minValue, number } from "react-admin";

import Grid from "@material-ui/core/Grid";

import { FormSelectInput } from "components/forms/select";

export const EditTeamMember = props => (
  <Edit {...props}>
    <SimpleForm>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ width: "35%" }}
      >
        <Grid item md={6} direction="column">
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <TextInput
            source="email"
            type="email"
            validate={[required(), email()]}
          />
          <TextInput source="phoneNumber" type="phone" />
          <FormSelectInput
            type={"SelectInput"}
            name={"Type"}
            enumName={"TeamMemberType"}
            validate={required()}
          />
          <FormSelectInput
            type={"RadioButtonGroupInput"}
            name={"State"}
            enumName={"TeamMemberState"}
            validate={required()}
          />
          <FormSelectInput
            type={"SelectInput"}
            name={"Job"}
            enumName={"TeamMemberJob"}
            validate={required()}
          />
          <TextInput source="jobTitle" validate={required()} />
        </Grid>
        <Grid item md={6} direction="column">
          <SelectInput
            source="grade"
            choices={[
              { id: 1, name: "1" },
              { id: 2, name: "2" },
              { id: 3, name: "3" },
              { id: 4, name: "4" },
              { id: 5, name: "5" }
            ]}
            validate={required()}
          />
          <FormSelectInput
            type={"RadioButtonGroupInput"}
            name={"Clan"}
            enumName={"TeamMemberClan"}
            validate={required()}
          />
          <FormSelectInput
            type={"SelectInput"}
            name={"School"}
            enumName={"TeamMemberSchool"}
            validate={required()}
          />

          <NumberInput
            source="hourlyCost"
            validate={[required(), number(), minValue(0)]}
          />

          <FormSelectInput
            type={"RadioButtonGroupInput"}
            name={"Location"}
            enumName={"TeamMemberLocation"}
            validate={required()}
          />
          <TextInput source="remoteLocation" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default EditTeamMember;

import { types } from "../../graphql";
import pluralize from "pluralize";
import { get } from "lodash";
import { set } from "lodash/fp";
import { lowerCaseFirst, notEqual, notEmpty } from "utils/js";
import { noter } from "services/noter";

const getErrorMessage = response => {
  if (response.isError()) {
    noter.info(`[DATA-PROVIDER] error: ${response.getErrorMessage()}`);
  }

  if (!response.getData()) {
    noter.error(`[DATA-PROVIDER] error: No data received`, response);
    throw new Error("No data received");
  } else {
    noter.info("[DATA-PROVIDER] got data");
  }
};

function toDataProviderListResponse(response) {
  getErrorMessage(response);
  return {
    data: response.getData(),
    total: response.getTotalCount()
  };
}

function toDataProviderOneResponse(response) {
  getErrorMessage(response);
  return { data: get(response.getData(), "data") };
}

function getChangedData({ id, data, previousData }) {
  return Object.entries(data).reduce(
    (changedData, [key, value]) => {
      const currentValue = value;
      const previousValue = previousData[key];
      // previous value is usually returned from api that means that connections will have nodes/edges format and current
      // value will probably be array of ids...but for now this is YAGNI
      if (notEqual(currentValue, previousValue)) {
        return { ...changedData, [key]: value };
      }

      return changedData;
    },
    { id }
  );
}

export const dataProvider = {
  getList: (resource, { pagination, filter }) => {
    const apiFilter = {
      ...filter,
      ...(filter.q ? { search: { query: filter.q } } : {})
    };

    if (apiFilter.q) {
      delete apiFilter.q;
    }

    const input = {
      offset: pagination,
      ...(notEmpty(apiFilter) ? { filter: apiFilter } : {})
    };
    const resourceName = lowerCaseFirst(resource);
    const type = types[resourceName];

    if (type) {
      const operation = get(type, `queries.${pluralize(resourceName)}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider getList ] operation ${pluralize(
            resourceName
          )} on type queries does not exist`
        );
      }

      return operation({ input }).then(toDataProviderListResponse);
    }

    throw new Error(
      `[ dataProvider getList ] type for resource ${resource} does not exist`
    );
  },
  getOne: (resource, { id }) => {
    const resourceName = lowerCaseFirst(resource);
    const type = types[resourceName];

    if (type) {
      const operation = get(type, `queries.${resourceName}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider getOne ] operation ${resourceName} on type queries does not exist`
        );
      }

      return operation({ id }).then(toDataProviderOneResponse);
    }

    throw new Error(
      `[ dataProvider getOne ] type for resource ${resource} does not exist`
    );
  },
  getMany: (resource, { ids }) => {
    ids = ids.map(data => data.id || data);
    const input = { filter: { ids } };
    const resourceName = lowerCaseFirst(resource);
    const type = types[resourceName];

    if (type) {
      const operation = get(type, `queries.${pluralize(resourceName)}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider getMany ] operation ${pluralize(
            resourceName
          )} on type queries does not exist`
        );
      }

      return operation({ input }).then(toDataProviderListResponse);
    }

    throw new Error(
      `[ dataProvider getMany ] type for resource ${resource} does not exist`
    );
  },
  getManyReference: (
    resource,
    { target, id, pagination, sort, filter = {} }
  ) => {
    const input = { filter: set(target, id, filter), offset: pagination };

    const resourceName = lowerCaseFirst(resource);
    const type = types[resourceName];

    if (type) {
      const operation = get(type, `queries.${pluralize(resourceName)}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider getMany ] operation ${pluralize(
            resourceName
          )} on type queries does not exist`
        );
      }

      return operation({ input }).then(toDataProviderListResponse);
    }

    throw new Error(
      `[ dataProvider getMany ] type for resource ${resource} does not exist`
    );
  },
  create: (resource, { data: input }) => {
    const resourceName = lowerCaseFirst(resource);
    const type = types[resourceName];

    if (type) {
      const operation = get(type, `mutations.create${resource}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider create ] operation create${resource} on type mutations does not exist`
        );
      }

      return operation({ input }).then(toDataProviderOneResponse);
    }

    throw new Error(
      `[ dataProvider create ] type for resource ${resource} does not exist`
    );
  },
  update: (resource, { id, data, previousData }) => {
    const input = getChangedData({ id, data, previousData });
    const type = types[lowerCaseFirst(resource)];

    if (type) {
      const operation = get(type, `mutations.update${resource}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider update ] operation update${resource} on type mutations does not exist`
        );
      }

      return operation({ input }).then(toDataProviderOneResponse);
    }

    throw new Error(
      `[ dataProvider update ] type for resource ${resource} does not exist`
    );
  },
  updateMany: (resource, params) => {
    throw new Error("updateMany in dataProvider needs implementation!!!");
  },
  delete: (resource, { id }) => {
    noter.info(`[DATA-PROVIDER][DELETE-ONE][${resource}] ${id}`);
    const type = types[lowerCaseFirst(resource)];

    if (type) {
      const operation = get(type, `mutations.delete${resource}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider delete ] operation delete${resource} on type mutations does not exist`
        );
      }

      return operation({ id }).then(toDataProviderOneResponse);
    }

    throw new Error(
      `[ dataProvider delete ] type for resource ${resource} does not exist`
    );
  },
  deleteMany: (resource, { ids }) => {
    noter.info(
      `[DATA-PROVIDER][DELETE-MANY][${resource}] ${JSON.stringify(ids)}`
    );

    const type = types[lowerCaseFirst(resource)];

    if (type) {
      const operation = get(type, `mutations.delete${pluralize(resource)}`);

      if (!operation) {
        throw new Error(
          `[ dataProvider delete ] operation delete${pluralize(
            resource
          )} on type mutations does not exist`
        );
      }

      return operation({ ids }).then(toDataProviderOneResponse);
    }

    throw new Error(
      `[ dataProvider delete ] type for resource ${resource} does not exist`
    );
  }
};

export default dataProvider;

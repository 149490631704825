import gql from "graphql-tag";
import { mutation } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const deleteActivityMutation = gql`
  mutation deleteActivity($id: ID!) {
    deleteActivity(id: $id) {
      data {
        ...activityDetails
      }
      error
    }
  }
  ${activitiesDetailsFragment}
`;

export default mutation(deleteActivityMutation);

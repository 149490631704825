import gql from "graphql-tag";
import { query } from "services/api";
import { teamMemberDetailsFragment } from "graphql/team-member/fragments/team-member-details";

export const teamMemberQuery = gql`
  query teamMember($id: ID!) {
    teamMember(id: $id) {
      data {
        ...teamMemberDetails
      }
    }
  }
  ${teamMemberDetailsFragment}
`;

export default query(teamMemberQuery);

import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "components/utility/Loader";
import { InlineError } from "components/utility/InlineError";
import { apolloClient } from "services/apollo";
import { RadioButtonGroupInput, SelectInput } from "react-admin";
import { getChoice } from "components/forms/utils";
import { get } from "lodash";

/**
 * Map enumerated type values to a select input.
 * This queries the service and returns an choice-based selectable from React-Admin
 * @param {string} enumName name of the enumareted query type;
 * 'TeamMemberRole', 'ActivityStateEnum', ...
 * @param {string} name name if the type to display
 * @param {'SelectInput'|'RadioButtonGroupInput'} [type='SelectInput']
 * @returns {ReactElement}
 * @constructor
 */
export const FormSelectInput = ({ name, enumName, type, validate }) => {
  const { loading, error, data } = useQuery(
    gql`
      query ${enumName} {
          __type(name:"${enumName}") {
              name
              enumValues {name}
          }
      }
  `,
    {
      client: apolloClient
    }
  );

  if (loading) {
    return <Loader width={"18px"} height={"18px"} />;
  }

  if (error) {
    return <InlineError message={`Error fetching ${name}`} />;
  }

  const choices = get(data, "__type.enumValues", [])
    .map(choice => getChoice(choice))
    .filter(Boolean);

  if (choices.length < 1) {
    return null;
  }

  if (type === "RadioButtonGroupInput") {
    return (
      <RadioButtonGroupInput
        source={name.toLowerCase()}
        choices={choices}
        validate={validate}
      />
    );
  }

  return (
    <SelectInput
      source={name.toLowerCase()}
      choices={choices}
      validate={validate}
    />
  );
};

export default SelectInput;

import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { required } from "react-admin";

export const CreateActivityGroup = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" multiline validate={required()} />
    </SimpleForm>
  </Create>
);

export default CreateActivityGroup;

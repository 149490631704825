import gql from "graphql-tag";
import { mutation } from "services/api";
import { teamMemberDetailsFragment } from "graphql/team-member/fragments/team-member-details";

export const updateTeamMemberMutation = gql`
    mutation updateTeamMember($input: UpdateTeamMemberInput!) {
        updateTeamMember(input: $input) {
            data {
                ...teamMemberDetails
            }
        }
    }
    ${teamMemberDetailsFragment}
`;

export default mutation(updateTeamMemberMutation);

import gql from "graphql-tag";
import { query } from "services/api";
import { projectDetailsFragment } from "graphql/task-project/fragments/project-details";

export const projectsQuery = gql`
  query taskProject($input: TaskProjectInput!) {
    taskProject(input: $input) {
      data {
        ...projectDetails
      }
      error
      success
    }
  }
  ${projectDetailsFragment}
`;

export default query(projectsQuery);

import gql from "graphql-tag";
import { mutation } from "services/api";
import { activityGroupDetailsFragment } from "graphql/activity-group/fragments/activity-group-details";

export const deleteActivityGroupMutation = gql`
  mutation deleteActivityGroup($input: DeleteActivityGroupInput!) {
    deleteActivityGroup(input: $input) {
      data {
        ...activityGroupDetails
      }
        error
    }
  }
  ${activityGroupDetailsFragment}
`;

export default mutation(deleteActivityGroupMutation);

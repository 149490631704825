import gql from "graphql-tag";
import { mutation } from "services/api";
import { activitiesDetailsFragment } from "graphql/activity/fragments/activity-details";

export const updateActivityMutation = gql`
    mutation updateActivity($input: UpdateActivityInput!) {
        updateActivity(input: $input) {
            data {
                ...activityDetails
            }
            error
        }
    }
    ${activitiesDetailsFragment}
`;

export default mutation(updateActivityMutation);

import gql from "graphql-tag";
import { query } from "services/api";
import { toggleProjectDetailsFragment } from "graphql/toggle-project/fragments/toggle-project-details";

export const projectsQuery = gql`
  query toggleProjects($input: ToggleProjectsInput) {
    toggleProjects(input: $input) {
      totalCount
      count
      nodes {
        ...toggleProjectDetails
      }
    }
  }
  ${toggleProjectDetailsFragment}
`;

export default query(projectsQuery);

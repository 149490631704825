import gql from "graphql-tag";
import { query } from "services/api";
import { jiraTempoAccountDetailsFragment } from "graphql/jira-tempo-account/fragments/project-details";

export const jiraTempoAccountsQuery = gql`
  query jiraTempoAccounts($input: JiraTempoAccountsInput) {
    jiraTempoAccounts(input: $input) {
      totalCount
      count
      nodes {
        ...jiraAccountDetails
      }
    }
  }
  ${jiraTempoAccountDetailsFragment}
`;

export default query(jiraTempoAccountsQuery);

import gql from "graphql-tag";
import { mutation } from "services/api";
import { activityGroupDetailsFragment } from "graphql/activity-group/fragments/activity-group-details";

export const createActivityGroupMutation = gql`
  mutation createActivityGroup($input: CreateActivityGroupInput!) {
    createActivityGroup(input: $input) {
      data {
        ...activityGroupDetails
      }
      error
    }
  }
  ${activityGroupDetailsFragment}
`;

export default mutation(createActivityGroupMutation);

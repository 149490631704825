export const COLOR = {
  black9Y: '#0B0C0D',
  white9Y: '#FFFFFF',
  blue9Y: '#2667FF',
  mainPrimary: '#1F3D55',
  mainSecondary: '#1A4A6F',
  mainTernary: '#2368A4',
  neutralPrimary: '#222931',
  neutralSecondary: '#5F6B7B',
  neutralTernary: '#BDC3C7',
  selectPrimary: '#299188',
  selectSecondary: '#3CAEA7',
  selectTernary: '#65DEC9',
  accentPrimary: '#CAA53C',
  accentSecondary: '#F4CA64',
  accentTernary: '#F8E49F',
  warningPrimary: '#B91F1F',
  warningSecondary: '#DD2F2E',
  warningTernary: '#DF685C',
  white: '#F5F5F5'
}

export const MAX_WIDTH = 768

export const FONT = 'Courier New, monospace'

export const MEDIA_BREAKPOINTS = {
  sm: '36em',
  md: '48em',
  lg: '62em',
  xl: '75em'
}

export const MEDIA_QUERIES = {
  min: {
    sm: `@media only screen and (min-width: ${ MEDIA_BREAKPOINTS.sm })`,
    md: `@media only screen and (min-width: ${ MEDIA_BREAKPOINTS.md })`,
    lg: `@media only screen and (min-width: ${ MEDIA_BREAKPOINTS.lg })`,
    xl: `@media only screen and (min-width: ${ MEDIA_BREAKPOINTS.xl })`,
  },
  max: {
    sm: `@media only screen and (max-width: ${ MEDIA_BREAKPOINTS.sm })`,
    md: `@media only screen and (max-width: ${ MEDIA_BREAKPOINTS.md })`,
    lg: `@media only screen and (max-width: ${ MEDIA_BREAKPOINTS.lg })`,
    xl: `@media only screen and (max-width: ${ MEDIA_BREAKPOINTS.xl })`,
  }
}

export const matchesMediaQueryBreakPoint = (query) => {
  let matches = false;
  try {
    matches = !window.matchMedia(query).matches;
  } catch (e) {
    matches = false;
  }

  return matches;
}

import ApolloClient from "apollo-client";
import apolloLogger from "apollo-link-logger";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { ENV_CONFIG } from "config";
import { OAUTH_TOKEN_NAME } from "providers/auth-provider";

const uri = `${ENV_CONFIG.FND_SERVICE_BASE_URL}/graphql`;
const httpLink = new HttpLink({ uri });
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(OAUTH_TOKEN_NAME);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const operationNameLink = setContext(
  async ({ operationName }, { headers = {} }) => {
    return {
      uri: `${uri}?operationName=${operationName}`,
      headers
    };
  });

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([apolloLogger, authLink.concat(operationNameLink.concat(httpLink))]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
});

export default apolloClient;

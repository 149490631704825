import gql from "graphql-tag";
import { query } from "services/api";
import { projectDetailsFragment } from "graphql/work-log-project/fragments/work-log-project-details";

export const projectsQuery = gql`
  query workLogProject($input: WorkLogProjectInput!) {
    workLogProject(input: $input) {
      data {
        ...projectDetails
      }
      error
      success
    }
  }
  ${projectDetailsFragment}
`;

export default query(projectsQuery);

import gql from "graphql-tag";

export const teamMemberDetailsFragment = gql`
  fragment teamMemberDetails on TeamMember {
    id
    fullName
    firstName
    lastName
    email
    type
    state
    job
    location
    jobTitle
    remoteLocation
    phoneNumber
    grade
    school
    clan
    hourlyCost
  }
`;

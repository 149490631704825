import gql from "graphql-tag";
import { mutation } from "services/api";
import { activityGroupDetailsFragment } from "graphql/activity-group/fragments/activity-group-details";

export const updateActivityGroupMutation = gql`
    mutation updateActivityGroup($input: UpdateActivityGroupInput!) {
        updateActivityGroup(input: $input) {
            data {
                ...activityGroupDetails
            }
        }
        error
    }
    ${activityGroupDetailsFragment}
`;

export default mutation(updateActivityGroupMutation);

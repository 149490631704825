/**
 * @typedef {Object<string, string|number|boolean|null>} FoundationEnvConfig
 * @property {string} ENV
 * @property {string} FND_SERVICE_BASE_URL
 * @property {string} FND_CLIENT_BASE_URL
 * @property {boolean} LOG_DEBUG
 * @property {boolean} ENABLE_AUTH
 */

/**
 * @returns {FoundationEnvConfig}
 */
const getConfig = () => {
    const ENV = process.env.REACT_APP_ENV || 'development'
    const ENABLE_AUTH = (process.env.hasOwnProperty('REACT_APP_ENABLE_AUTH') ? process.env.REACT_APP_ENABLE_AUTH : 'true') === 'true'
    const LOG_DEBUG = (process.env.hasOwnProperty('REACT_APP_LOG_DEBUG') ? process.env.REACT_APP_LOG_DEBUG : 'true') === 'true'
    const FND_SERVICE_BASE_URL = process.env.REACT_APP_FND_SERVICE_BASE_URL
    const FND_CLIENT_BASE_URL = window.location.origin
    const configs = {
        development: {
            ENV,
            FND_SERVICE_BASE_URL: FND_SERVICE_BASE_URL || 'http://localhost:5000',
            FND_CLIENT_BASE_URL,
            LOG_DEBUG,
            ENABLE_AUTH
        },
        test: {
            ENV,
            FND_SERVICE_BASE_URL,
            FND_CLIENT_BASE_URL,
            LOG_DEBUG,
            ENABLE_AUTH
        },
        staging: {
            ENV,
            FND_SERVICE_BASE_URL,
            FND_CLIENT_BASE_URL,
            LOG_DEBUG,
            ENABLE_AUTH
        },
        production: {
            ENV,
            FND_SERVICE_BASE_URL,
            FND_CLIENT_BASE_URL,
            LOG_DEBUG,
            ENABLE_AUTH
        },
    }


    if (!configs.hasOwnProperty(ENV)) {
        throw new Error(`Invalid environment "${ENV}" provided`)
    }

    const CONFIG = configs[ENV]
    for (const envVar in CONFIG) {
        const envVal = CONFIG[envVar]
        if (envVar.endsWith('_URL') && envVal.endsWith('/')) {
            CONFIG[envVar] = envVal.substring(0, envVal.length - 1)
        }
    }

    if (['development', 'test'].includes(CONFIG.ENV)) {
        console.log(`Setting configuration for ${ENV} environment: ${JSON.stringify(CONFIG, null, 2)}`)
    }

    return CONFIG
}

/**
 * @type FoundationEnvConfig
 */
export const ENV_CONFIG = getConfig()

export default ENV_CONFIG

import { isEqual, isEmpty, isNull, isUndefined } from "lodash";

export const notEqual = (...params) => isEqual(...params) === false;

export const notEmpty = (...params) => isEmpty(...params) === false;

export const notNull = value => isNull(value) === false;

export const isDefined = value => isUndefined(value) === false;

export function isInt(number) {
  return Number(number) === number && number % 1 === 0;
}

export function isFloat(number) {
  return Number(number) === number && number % 1 !== 0;
}

export function arrayIntersection(array1 = [], array2 = []) {
  return [...new Set(array1)].filter(element =>
    [...new Set(array2)].includes(element)
  );
}

export function asyncAction(promise) {
  return Promise.resolve(promise)
    .then(data => [null, data])
    .catch(error => [error]);
}

/**
 * Sends binary file to a remote server.
 * @return {Promise<*>} Returns empty promise or reject on errors.
 */
export async function uploadFile({ filePath, uploadUrl, headers = {} }) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error(xhr.response));
      }
    });

    xhr.addEventListener("error", reject);
    xhr.open("POST", uploadUrl);
    Object.keys(headers).forEach(headerKey => {
      xhr.setRequestHeader(headerKey, headers[headerKey]);
    });
    xhr.send({ uri: filePath });
  });
}

export function lowerCaseFirst(string = "") {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

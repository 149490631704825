import gql from "graphql-tag";
import { mutation } from "services/api";
import { teamMemberDetailsFragment } from "graphql/team-member/fragments/team-member-details";

export const createTeamMemberMutation = gql`
  mutation createTeamMember($input: CreateTeamMemberInput!) {
    createTeamMember(input: $input) {
      data {
        ...teamMemberDetails
      }
    }
  }
  ${teamMemberDetailsFragment}
`;

export default mutation(createTeamMemberMutation);

import gql from "graphql-tag";

export const activitiesDetailsFragment = gql`
  fragment activityDetails on Activity {
    id
    name
    description
    state
    type
    billable
    dueDate
    salesBudgetEstimate
    productBudgetEstimate
    customer
    deletedAt
    team: teamData {
      activityId
      teamMemberId
      roles
      leadRoles
    }
    totalDuration {
      value
      label
    }
    groups {
      nodes {
        id
        name
      }
    }
  }
`;

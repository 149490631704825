import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ListPagination } from "components/forms/pagination";

export const ActivityGroupList = props => (
  <List pagination={<ListPagination />}  {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default ActivityGroupList;

import React from "react";
import { COLOR } from "style/theme";
import styled from "styled-components";
import ReactLoading from "react-loading";

const Container = styled.div`
  padding: auto;
  height: 100%;
  width: 100%;
`

const LoaderContainer = styled(ReactLoading)`
  margin: auto;
`

/**
 *
 * @param {string} [height='64px']
 * @param {string} [width='64px']
 * @param {'blank'|'balls'|'bars'|'bubbles'|'cubes'|'cylon'|'spin'|'spokes'} [type='spin']
 * @returns {*}
 * @constructor
 */
export const Loader = ({
  height = '64px',
  width = '64px',
  type = 'spin'
}) => {
  return (
    <Container>
      <LoaderContainer
        height={height}
        width={width}
        type={type}
        color={COLOR.blue9Y} />
    </Container>
  )
}

import React from "react";
import { COLOR } from "style/theme";
import styled from "styled-components";

const Container = styled.div`
  padding: auto;
  height: 100%;
  width: 100%;
`

const ErrorContainer = styled.div`
  margin: auto;
  font-size: 12px;
  line-height: 18px;
  color: ${COLOR.warningPrimary};
`

export const InlineError = ({ message = 'Error occurred' }) => {
  return (
    <Container>
      <ErrorContainer>{message}</ErrorContainer>
    </Container>
  )
}

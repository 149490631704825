import React from "react";
import {
  Edit,
  TextInput,
  DateTimeInput,
  RadioButtonGroupInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  NumberInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  ReferenceInput,
  ArrayInput,
  CheckboxGroupInput,
  SimpleFormIterator,
  AutocompleteInput
} from "react-admin";

import List from "components/list";

import { required, minValue, number } from "react-admin";

import { Grid } from "@material-ui/core";

import { FormSelectInput } from "components/forms/select";

export const EditActivity = props => {
  return (
    <React.Fragment>
      <Edit {...props}>
        <TabbedForm>
          <FormTab label="activity">
            <Grid container style={{ width: "25%" }} direction="column">
              <TextInput source="name" validate={required()} />
              <TextInput source="description" multiline validate={required()} />
              <TextInput
                label="Total duration in hours"
                source="totalDuration.label"
                disabled
              />
              <FormSelectInput
                enumName={"ActivityStateEnum"}
                type={"SelectInput"}
                name={"State"}
                validate={required()}
              />
              <FormSelectInput
                enumName={"ActivityTypeEnum"}
                type={"RadioButtonGroupInput"}
                name={"Type"}
                validate={required()}
              />
              <RadioButtonGroupInput
                source="billable"
                choices={[{ id: true, name: "Yes" }, { id: false, name: "No" }]}
              />

              <DateTimeInput
                label="Due date"
                source="dueDate"
                validate={required()}
              />

              <ReferenceArrayInput
                label="Activity groups"
                source="groups"
                reference="ActivityGroup"
                format={data => {
                  if (Array.isArray(data)) {
                    return data;
                  }
                  return data.nodes.map(({ id }) => id);
                }}
                validate={required()}
              >
                <AutocompleteArrayInput
                  optionText="name"
                  optionValue="id"
                  format={data => data.nodes}
                />
              </ReferenceArrayInput>

              <NumberInput
                source="salesBudgetEstimate"
                validate={[required(), number(), minValue(0)]}
              />
              <NumberInput
                source="productBudgetEstimate"
                validate={[required(), number(), minValue(0)]}
              />
              <TextInput source="customer" validate={required()} />
            </Grid>
          </FormTab>
          <FormTab label="Team">
            <ArrayInput
              label="Activity team"
              source="team"
              validate={required()}
            >
              <SimpleFormIterator>
                <ReferenceInput
                  label="Team member"
                  source="teamMemberId"
                  reference="TeamMember"
                  validate={required()}
                >
                  <AutocompleteInput optionText="fullName" optionValue="id" />
                </ReferenceInput>
                <AutocompleteArrayInput
                  label="Roles"
                  source="roles"
                  choices={[
                    { id: "ENGINEER", name: "Engineer" },
                    { id: "DESIGNER", name: "Designer" },
                    { id: "PRODUCT_MANAGER", name: "Product Manager" },
                    { id: "MARKETING_ANALYST", name: "Marketing Analyst" },
                    { id: "QUALITY_ASSURANCE", name: "Quality Assurance" }
                  ]}
                  validate={required()}
                />
                <CheckboxGroupInput
                  label="Lead roles"
                  source="leadRoles"
                  choices={[
                    { id: "ACTIVITY_LEAD", name: "Activity lead" },
                    { id: "TECH_LEAD", name: "Tech lead" },
                    { id: "DESIGN_LEAD", name: "Design lead" }
                  ]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="work logs">
            <List
              title="Activity work logs"
              filter={{ activityId: props.id }}
              style={{ width: "100%" }}
              resourceName="WorkLog"
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="id" />
                <TextField source="author.fullName" label="Author" />
                <TextField source="description" />
                <DateField source="start" showTime />
                <DateField source="end" showTime />
                <TextField source="duration.label" label="Duration" />
                {/*<ArrayField source="tags" />*/}
              </Datagrid>
            </List>
          </FormTab>
          <FormTab label="tasks">
            <List
              title="Activity tasks"
              filter={{ activity: { id: props.id } }}
              style={{ width: "100%" }}
              resourceName="Task"
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="id" />
                <TextField source="assignee.fullName" label="Assignee" />
                <TextField source="author.fullName" label="Author" />
                <TextField source="description" />
                <TextField source="status" />
              </Datagrid>
            </List>
          </FormTab>
        </TabbedForm>
      </Edit>
    </React.Fragment>
  );
};

export default EditActivity;

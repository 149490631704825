import React from "react";
import { Pagination } from "react-admin";

export const ListPagination = ({page, perPage, setPage, setPerPage, total}) => (
  <Pagination
    page={page}
    rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
    setPage={page => setPage(page)}
    setPerPage={perPage => setPerPage(perPage)}
    total={total}
    perPage={perPage}
  />
)


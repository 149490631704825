import gql from "graphql-tag";
import { query } from "services/api";
import { taskDetailsFragment } from "graphql/task/fragments/task-details";

export const tasksQuery = gql`
  query tasks($input: TasksInput) {
    tasks(input: $input) {
      totalCount
      count
      nodes {
        ...taskDetails
      }
    }
  }
  ${taskDetailsFragment}
`;

export default query(tasksQuery);

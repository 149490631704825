import gql from "graphql-tag";
import { query } from "services/api";
import { activityGroupDetailsFragment } from "graphql/activity-group/fragments/activity-group-details";

export const activityGroupsQuery = gql`
  query activityGroups($input: ActivityGroupsInput) {
    activityGroups(input: $input) {
      totalCount
      count
      nodes {
        ...activityGroupDetails
      }
    }
  }
  ${activityGroupDetailsFragment}
`;

export default query(activityGroupsQuery);

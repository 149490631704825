import { get, capitalize } from "lodash";

const EXACT_NAMING_MAP = {
  AT: "Austria",
  HR: "Croatia",
  DE: "Germany",
  CTO: "CTO",
  CEO: "CEO",
  COO: "COO",
  CFO: "CFO",
  CT: "CT",
  CRO: "CRO",
  DPO: "DPO",
  HOP: "HOP",
  HOF: "HOF",
  HOD: "HOD",
  HOM: "HOM",
  HOPe: "HOPe"
};

/**
 * From an enum type build a choice for choice-based input types.
 * @param {{name: string}} choice
 * @returns {{name: string, id: string}|null}
 */
export const getChoice = choice => {
  const NAME = get(choice, "name", undefined);
  if (!NAME) {
    return null;
  }

  const name = NAME.split("_");
  for (let i = 0; i < name.length; i++) {
    const nameValue = name[i];
    let val = "";
    if (EXACT_NAMING_MAP.hasOwnProperty(nameValue)) {
      val = EXACT_NAMING_MAP[nameValue];
    } else if (i === 0) {
      val = capitalize(nameValue);
    } else {
      val = nameValue.toLowerCase();
    }

    name.splice(i, 1, val);
  }

  return {
    id: choice.name.toUpperCase(),
    name: name.join(" ")
  };
};

import gql from "graphql-tag";

export const taskDetailsFragment = gql`
  fragment taskDetails on Task {
    id
    name
    description
    status
    assignee {
      id
      fullName
    }
    author {
      id
      fullName
    }
    updatedAt
  }
`;

import { get, isEmpty, isArray, isObject, extend } from "lodash";
import { notEmpty } from "utils/js";

class Response {
  constructor(response) {
    response = response || {};
    extend(this, response);
    this._response = response || {};
    this.error = false;
  }

  getData = (defaultData = null) => {
    if (this._response.edges) {
      return this._response.edges.map(({ node }) => node);
    }

    if (this._response.nodes) {
      return this._response.nodes;
    }

    return this._response && isEmpty(this._response)
      ? defaultData
      : this._response;
  };

  getField = key => get(this.getData(), key);

  getCount = () => {
    const data = this.getData();
    if (isArray(data)) {
      return data.length;
    }

    if (isObject(data) && notEmpty(data)) {
      return 1;
    }

    return 0;
  };

  getTotalCount = () => {
    return get(this._response, 'totalCount', undefined)
  }

  getError = (key = null) => get(this.error, key, this.error);

  getErrorMessage = () =>
    this.getError('message').replace('GraphQL error: ', '');

  isError = () => isEmpty(this.error) === false;

  notError = () => isEmpty(this.error);
}

export default Response;

import React from "react";

import { ENV_CONFIG } from "config";
import { noter } from "services/noter";
import GoogleButton from "react-google-button";
import { Container, ButtonContainer } from "screens/login/style";

export const LoginPage = () => {

  const handleClick = () => {
    const loginUrl = `${ENV_CONFIG.FND_SERVICE_BASE_URL}/v1/users/google/login?redirectURL=${
      encodeURIComponent(`${ENV_CONFIG.FND_CLIENT_BASE_URL}/token`)
    }`
    noter.info(`Google login clicked - going to ${loginUrl}`)
    window.location.href = loginUrl
  }

  return (
    <Container>
      <ButtonContainer>
        <GoogleButton onClick={handleClick} />
      </ButtonContainer>
    </Container>
  )
}

import createActivity from "graphql/activity/mutations/create-activity";
import updateActivity from "graphql/activity/mutations/update-activity";
import deleteActivity from "graphql/activity/mutations/delete-activity";
import deleteActivities from "graphql/activity/mutations/delete-activities";

export default {
  createActivity,
  updateActivity,
  deleteActivity,
  deleteActivities
};
